import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, IconButton } from "@mui/material";
import {
  SearchOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import { Card, Input, Image } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";


const MdShare_Menu = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://checkkonproject-sub.com/api/MdShare_request");

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          setData(data);
        } else {
          console.error("Unexpected content type:", contentType);
          throw new Error("Server returned non-JSON response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleSearch = (event) => setSearchTerm(event.target.value);
  const filtered = data.filter((data) =>
    data.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedFiltered = filtered
    .filter((item) => item.status === 1)
    .sort((a, b) => b.id - a.id);

  return (
    <div className="backgroundColor">
      <Paper
        elevation={0}
        className="paperContainer"
        style={{ backgroundColor: "#f0f2f9" }}
      >
        <Card className="cardsection">
          <div className="cardsectionContent">
            สื่อชวนแชร์
            <div className="searchContainer">
              <Input
                type="text"
                size="large"
                placeholder="ค้นหา"
                variant="filled"
                style={{
                  marginRight: "10px",
                  borderRadius: "25px",
                  height: "50px", // Adjust this value for taller input
                  padding: "10px", // Optional: to adjust the inner spacing
                  backgroundColor: "white", // Set background color to white
                  color: "black", // Text color
                }}
                onChange={handleSearch}
                prefix={<SearchOutlined className="site-form-item-icon" />}
              />
            </div>
          </div>
        </Card>
        <br />
        <Grid container spacing={2}>
          {sortedFiltered
            .slice(indexOfFirstItem, indexOfLastItem)
            .map((item) => (
              <Grid item xs={12} md={4} key={item.id} className="gridItem">
                <Link
                  to={`/MediaShare_Menu/MediaShare_view/${item.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    hoverable
                    className="cardItem"
                    cover={
                      <div className="cardItemCover">
                        <Image
                          className="cardImage"
                          src={item.cover_image}
                        />
                        <div className="cardIitleTCover">
                          <strong>
                            เผยแพร่{" "}
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </strong>
                          <br />
                          {item.title.length > 150 ? (
                            <div
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 3, // จำกัดการแสดงผลเพียง 3 บรรทัด
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "25px", // ขนาดตัวอักษรตามต้องการ
                              }}
                            >
                              {item.title}
                            </div>
                          ) : (
                            item.title
                          )}
                        </div>
                      </div>
                    }
                  />
                </Link>
              </Grid>
            ))}
        </Grid>
        {data.length > 0 && (
          <Box mt={4} display="flex" justifyContent="center">
            <IconButton
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <LeftCircleOutlined
                style={{ fontSize: "3rem", color: "#7BBD8F" }}
              />
            </IconButton>
            <IconButton
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= data.length}
            >
              <RightCircleOutlined
                style={{ fontSize: "3rem", color: "#7BBD8F" }}
              />
            </IconButton>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default MdShare_Menu;
