import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Th Sarabun New",
      "sans-serif",
    ].join(","),
  },
  Form: {
    fontFamily: [
      "Th Sarabun New",
      "sans-serif",
    ].join(","),
  },
  barchart: {
    fontFamily: [
      "Th Sarabun New",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
  },
});

export default theme;
